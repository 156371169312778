/* @include box-shadow(1px,1px,1px,0, #fff, true); */
:root {
  --main-bg-color: $main_bg_color;
  --main-text-color: $main_text_color ;
}

@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@-webkit-keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@-moz-keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@-webkit-keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@-moz-keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }
  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@-moz-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

main .page_content {
  background-color: #fff;
  padding: 60px 0;
}

main .page_content form {
  width: 100%;
  margin: 0 auto;
  max-width: 650px;
}

main .page_content form .wpcf7-form-control-wrap {
  margin-bottom: 15px;
  display: block;
}

main .page_content form label {
  font-weight: bold;
}

main .page_content input, main .page_content textarea, main .page_content select {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border: 1px solid #564d4f;
  outline: none;
  margin: 0;
  padding: 10px;
  border-radius: 4px;
}

main .page_content input[type="submit"], main .page_content textarea[type="submit"], main .page_content select[type="submit"] {
  background-color: #263c85;
  max-width: 200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  -moz-transition: all ease .3s;
  transition: all ease .3s;
  font-weight: bold;
}

main .page_content input[type="submit"]:hover, main .page_content textarea[type="submit"]:hover, main .page_content select[type="submit"]:hover {
  background-color: #564d4f;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  -moz-transition: all ease .3s;
  transition: all ease .3s;
}

main a {
  color: #023c6a;
  font-weight: bold;
  text-decoration: underline;
}

.wp-container-4.wp-container-4, .wp-container-8.wp-container-8, .wp-container-9.wp-container-9, .wp-container-12.wp-container-12, .wp-container-15.wp-container-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.wp-container-4.wp-container-4 .wp-block-column, .wp-container-8.wp-container-8 .wp-block-column, .wp-container-9.wp-container-9 .wp-block-column, .wp-container-12.wp-container-12 .wp-block-column, .wp-container-15.wp-container-15 .wp-block-column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.3%;
     -moz-box-flex: 0;
      -ms-flex: 0 0 33.3%;
          flex: 0 0 33.3%;
  padding: 15px;
}

.wp-container-4.wp-container-4 .wp-block-heading, .wp-container-8.wp-container-8 .wp-block-heading, .wp-container-9.wp-container-9 .wp-block-heading, .wp-container-12.wp-container-12 .wp-block-heading, .wp-container-15.wp-container-15 .wp-block-heading {
  font-size: 1.4rem;
  line-height: 2.1rem;
  text-align: center;
  margin: 0;
  padding: 15px;
  background-color: #C5212F;
  color: white;
}

@media screen and (max-width: 768px) {
  .wp-container-4.wp-container-4, .wp-container-8.wp-container-8, .wp-container-9.wp-container-9, .wp-container-12.wp-container-12, .wp-container-15.wp-container-15 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .wp-container-4.wp-container-4 .wp-block-column, .wp-container-8.wp-container-8 .wp-block-column, .wp-container-9.wp-container-9 .wp-block-column, .wp-container-12.wp-container-12 .wp-block-column, .wp-container-15.wp-container-15 .wp-block-column {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    padding: 15px;
  }
  .wp-container-4.wp-container-4 .wp-block-heading, .wp-container-8.wp-container-8 .wp-block-heading, .wp-container-9.wp-container-9 .wp-block-heading, .wp-container-12.wp-container-12 .wp-block-heading, .wp-container-15.wp-container-15 .wp-block-heading {
    font-size: 1.4rem;
    line-height: 2.1rem;
    text-align: center;
    margin: 0;
    padding: 15px;
    background-color: #C5212F;
    color: white;
  }
}
